<template>
  <LoadingButton
      :processing="processing"
      btn-text="Assign"
      class="btn btn-success"
      processing-text="working..."
      @click.prevent="handleAssign"
  />
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import LoadingButton from "@/components/form/LoadingButton";

export default {
  name: "AssignUser",
  props: {
    application: {
      default: null
    },
    userId: {
      default: null
    }
  },
  emits: ["application-assigned"],
  data: () => ({
    processing: false
  }),
  watch: {
    async getPusherNotification(param) {
      switch (param.type) {
        case 'assign-application':
          this.processing = false;
          break;
      }
    }
  },
  components: {
    LoadingButton
  },
  computed: {
    ...mapGetters(["getPusherNotification"])
  },
  methods: {
    ...mapActions(["assignApplication"]),
    async handleAssign() {
      this.processing = true;
      let applicationId = this.application?.id;
      let userId = this.userId;
      await this.assignApplication({
        applicationId,
        userId
      })
          .then(() => {
            console.log(`AssignUser this.userId:${this.userId}`);
            this.$emit("application-assigned");
            this.$router.push({
              name: "Application Focus",
              params: {
                uuid: this.application?.uuid
              }
            });
          })
          .catch(err => console.log(err));
    }
  }
};
</script>
<style lang="scss" scoped></style>
