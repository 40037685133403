<template>
  <div>
    <div v-if="applicationsCompLength" class="table-responsive">
      <table class="table table-hover text-left">
        <thead>
        <tr>
          <th scope="col">id</th>
          <th v-if="!minCols" scope="col">Firstname</th>
          <th v-if="!minCols" scope="col">Lastname</th>
          <th v-if="!minCols" scope="col">Email</th>

          <th v-if="minCols" scope="col">Name</th>
          <th scope="col">Created</th>
          <th v-if="showStatus" scope="col">Status</th>
          <th v-if="assignmentUsage || unassignmentUsage" scope="col">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(application, index) in applicationsComp"
            :key="application.id"
            class="table-row"
        >
          <td scope="row">
            <span class="text-subdued">{{ application.id }}</span>
          </td>
          <td v-if="!minCols" scope="row">
              <span class="link-text" @click="handleSelect(application)">{{
                  application.first_name
                }}</span>
          </td>
          <td v-if="!minCols">
              <span class="link-text" @click="handleSelect(application)">{{
                  application.last_name
                }}</span>
          </td>
          <td v-if="!minCols">
              <span class="link-text" @click="handleSelect(application)">{{
                  application.email
                }}</span>
          </td>

          <td v-if="minCols" class="position-relative d-flex flex-row">
            <icon
                v-if="assignmentUsage && application.duplicate_found"
                :data="et"
                color="orange"
                dir="up"
                height="20"
                width="20"
                @click="handelDuplicate(application)"
            />&nbsp;
            <span class="link-text" @click="handleSelect(application)"
            >{{ application.first_name }} {{ application.last_name }}</span
            >
          </td>
          <td>{{ formatReadDateTime(application.created_at) }}</td>
          <td v-if="showStatus">
            <StatusUpdate
                :ref="'statusUpdateComponent_' + index"
                :entity="application"
                :reason-required="false"
                :with-title="false"
                type="app"
                @status-changed="handleStatusChange"
            />
          </td>
          <td v-if="assignmentUsage">
            <AssignUser
                :application="application"
                :user-id="getUser?.id"
                class="pointer_all"
                @application-assigned="handleAssigned"
            />
          </td>
          <td v-if="unassignmentUsage">
            <UnassignUser
                :application="application"
                :user-id="getUser?.id"
                class="pointer_all"
                @application-unassigned="handleAssigned"
            />
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else>No records found!</div>
  </div>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import StatusUpdate from "@/components/form/StatusUpdate";
import AssignUser from "@/components/application-manager/AssignUser";
import UnassignUser from "@/components/application-manager/UnassignUser";
import et from "@fa/solid/exclamation-triangle.svg";

export default {
  name: "ApplicationList",
  props: {
    applicationsProp: {
      default: null
    },
    linksProp: {
      default: null
    },
    metaProp: {
      default: null
    },
    searchApplication: {
      default: null
    },
    assignmentUsage: {
      type: Boolean,
      default: false
    },
    unassignmentUsage: {
      type: Boolean,
      default: false
    },
    minCols: {
      type: Boolean,
      default: false
    },
    showStatus: {
      type: Boolean,
      default: false
    },
    preLoaded: {
      type: Boolean,
      default: false
    }
  },
  components: {
    StatusUpdate,
    AssignUser,
    UnassignUser
  },
  emits: ["selected", "list-status-change", "duplicate-found"],
  data: () => ({
    applications: [],
    links: [],
    meta: [],
    et
  }),
  watch: {
    applicationsProp(n) {
      this.applications = n;
    },
    linksProp(n) {
      this.links = n;
    },
    metaProp(n) {
      this.meta = n;
    }
  },
  computed: {
    ...mapGetters(["getUser", "getStatusAppValues"]),
    applicationsComp() {
      if (this.preLoaded) {
        return this.searchApplication;
      } else {
        return this.searchApplication || this.applications;
      }
    },
    applicationsCompLength() {
      return this.applicationsComp?.length > 0;
    }
  },
  methods: {
    ...mapActions(["getApplications", "updateApplication"]),
    ...mapMutations(["setApplication"]),
    handelDuplicate(application) {
      this.$emit("duplicate-found", application);
    },
    handleAssigned() {
      this.$emit("list-status-change");
    },
    async handleStatusChange({status, uuid}) {
      let noUpdate = true;
      await this.updateApplication({status, uuid}, noUpdate).catch(err =>
          console.log(err)
      );
      for (let [key, value] of Object.entries(this.$refs)) {
        if (value !== null && /^statusUpdateComponent_/.test(key)) {
          value.edit = false;
          value.updating = false;
        }
      }
      this.$emit("list-status-change");
    },
    handleUpdate(response) {
      this.applications = response.data.data;
      this.links = response.data.links;
      this.meta = response.data.meta;
    },
    handleSelect(application) {
      this.setApplication(application);
      this.$emit("selected", application.uuid);
    }
  },
  async beforeMount() {
    if (this.applicationsProp == null && this.preLoaded) {
      let response = await this.getApplications().catch(err =>
          console.log(err)
      );
      this.applications = response.data.data;
      this.links = response.data.links;
      this.meta = response.data.meta;
    }
  }
};
</script>
<style lang="scss">
.duplicate-icon {
  margin-right: 10px;
}
</style>
