<template>
  <button :disabled="processing">
    <span class="blink" v-if="processing">
      {{ processingText }}
    </span>
    <span v-else>
      {{ btnText }}
    </span>
  </button>
</template>
<script>
export default {
  name: "LoadingButton",
  props: {
    processing: {
      default: false
    },
    btnText: {
      type: String,
      default: "Update"
    },
    processingText: {
      type: String,
      default: "Processing..."
    }
  }
};
</script>
